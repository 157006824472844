import React from 'react';
import { Category } from '../../models';
import AllCategoriesModal from '../modals/AllCategoriesModal';
import HomeCategoryItem from './HomeCategoryItem';

interface HomeCategoriesProps {
  categories: Category[];
  selectedCategory: Category;
  setSelectedCategory: React.Dispatch<React.SetStateAction<Category>>;
}

function HomeCategories({
  categories,
  selectedCategory,
  setSelectedCategory,
}: HomeCategoriesProps) {
  const [openAllCategoriesModal, setOpdenAllCategoriesModal] =
    React.useState(false);
  return (
    <div className="w-full h-auto mt-5 flex flex-row justify-start gap-5 items-center overflow-x-scroll py-5">
      {categories?.map((category) => (
        <div key={category._id}>
          <HomeCategoryItem
            category={category}
            setSelectedCategory={setSelectedCategory}
          />
        </div>
      ))}
      <div key="other">
        <HomeCategoryItem
          other
          setSelectedCategory={setSelectedCategory}
          setOpdenAllCategoriesModal={setOpdenAllCategoriesModal}
        />
      </div>
      <AllCategoriesModal
        isOpen={openAllCategoriesModal}
        setOpen={setOpdenAllCategoriesModal}
        close={() => {
          setOpdenAllCategoriesModal(false);
        }}
        categories={categories}
        setSelectedCategory={setSelectedCategory}
      />
    </div>
  );
}

export default HomeCategories;
