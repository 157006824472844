/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { Dialog, Transition } from '@headlessui/react';
import ClientOnlyPortal from './ClientOnlyPortal';
import CloseIcon from '../icons/CloseIcon';

interface ModalProps {
  children?: React.ReactNode;
  isOpen?: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  title?: string;
  onClose?: () => void;
}

export default function Modal({
  children,
  isOpen = false,
  setOpen,
  className = '',
  title,
  onClose,
}: ModalProps) {
  function closeModal() {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }
  return (
    <AnimatePresence>
      {isOpen ? (
        <ClientOnlyPortal selector="#modal">
          <Transition appear show={isOpen} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>
              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel
                      className={`top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-40 overflow-y-auto rounded-lg ${className} py-2 modal-scrollbar max-h-full bg-white`}
                    >
                      <Dialog.Title className="w-full flex flex-row justify-between items-center border-b border-neutral-300 p-8">
                        <div className="w-full text-base md:text-lg lg:text-2xl text-primary-300 font-semibold text-center font-century">
                          {title}
                        </div>
                        <button
                          type="button"
                          className="text-text-black hover:text-neutral-500"
                          onClick={(e) => {
                            e.preventDefault();
                            closeModal();
                          }}
                        >
                          <CloseIcon className="w-3 lg:w-4 text-neutral-300" />
                        </button>
                      </Dialog.Title>
                      <div className="w-full flex flex-col justify-center items-center text-center">
                        {children}
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </ClientOnlyPortal>
      ) : null}
    </AnimatePresence>
  );
}
