/* eslint-disable react/function-component-definition */
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from 'popmotion';
import Link from 'next/link';
import useSettings from '../../hooks/useSettings';

const xOffset = 1000;

const variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? xOffset : -xOffset,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    x: direction < 0 ? xOffset : -xOffset,
    opacity: 0,
  }),
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) =>
  Math.abs(offset) * velocity;

interface EllipseProps {
  type: string;
}

const Ellipse = ({ type }: EllipseProps) => (
  <div
    className={`h-2 md:h-3 mx-3 rounded-md cursor-pointer transform ease-in-out duration-500 ${
      type === 'dark'
        ? 'bg-primary-300 w-8 md:w-16'
        : 'bg-neutral-300 w-2 md:w-3'
    }`}
  />
);

function Carousel() {
  const [[page, direction], setPage] = React.useState([0, 0]);
  const [settings] = useSettings();

  const imageIndex = wrap(0, settings?.carouselImages?.length, page);
  const paginate = (newDirection: number) => {
    setPage([newDirection, newDirection - page]);
  };

  const getLink = (linkType: string, link: string) => {
    switch (linkType) {
      case 'product':
        return `/product/${link}`;
      case 'external':
        return link;
      default:
        break;
    }
  };

  React.useEffect(() => {
    const interval = setInterval(
      () => paginate((page + 1) % (settings?.carouselImages?.length ?? 1)),
      15000
    );
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, direction]);

  return (
    <div className="h-96 md:h-100 lg:h-110 xl:h-120 w-full relative flex justify-center items-center overflow-hidden bg-transparent z-0 rounded-lg">
      <Link
        href={
          getLink(
            settings?.carouselImages?.[imageIndex]?.linkType ?? '',
            settings?.carouselImages?.[imageIndex]?.link ?? ''
          ) ?? ''
        }
      >
        <a
          className={`w-full flex justify-center items-center ${
            settings?.carouselImages?.[imageIndex]?.linkType !== 'none'
              ? 'cursor-pointer'
              : 'cursor-default'
          } `}
        >
          <AnimatePresence initial={false} custom={direction}>
            <motion.img
              key={page}
              alt=""
              src={
                settings?.carouselImages?.[imageIndex]?.url ??
                'img/french_toast.png'
              }
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: 'ease' },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}
              className="h-full w-full object-center object-cover"
            />
          </AnimatePresence>
        </a>
      </Link>
      <div className="z-20 w-auto mb-2 sm:mb-7 absolute bottom-0 bg-background-100 rounded-full px-3 py-2 md:px-5 md:py-3">
        <div className="h-full w-full flex">
          {settings?.carouselImages?.map((img, index) => (
            <button
              type="button"
              aria-label="ellipse"
              key={index}
              onClick={(e) => {
                e.preventDefault();
                paginate(index);
              }}
            >
              <Ellipse type={index === page ? 'dark' : 'light'} />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Carousel;
