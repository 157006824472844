import React from 'react';
import NProgress from 'nprogress';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { Product, ShopCart } from '../../models';
import Modal from '../common/Modal';
import { useNotify, useUser } from '../../hooks';
import { useShopCart } from '../../context';
import { ADD_ITEM_TO_SHOP_CART } from '../../graphql/mutations';
import checkAmountInShopCart from '../../lib/checkAmountInShopCart';
import OptionSelect from '../product/OptionSelect';

interface OptionModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  close: () => void;
  product: Product;
}

interface OrderProductOptions {
  _id?: string;
  type?: 'fixed' | 'range' | 'optional';
  title: string;
  value: string;
  price: number;
  groupId?: string;
}

function OptionModal({ isOpen, setOpen, close, product }: OptionModalProps) {
  const [user] = useUser();
  const router = useRouter();
  const notify = useNotify();
  const [shopCart, setShopCart] = useShopCart();
  const [formatOptions, setFormatOptions] = React.useState<
    OrderProductOptions[]
  >([]);
  const [addingToCart, setAddingToCart] = React.useState(false);
  const [addItemToCart] = useMutation<{ addItemToCart: ShopCart }>(
    ADD_ITEM_TO_SHOP_CART
  );

  const onAddToCart = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!user) {
      router.push('/login');
      notify(
        'Debe iniciar sesión para poder agregar productos al carrito',
        'info'
      );
      return;
    }
    if (shopCart) {
      if (checkAmountInShopCart(shopCart, product) === 0) {
        return notify(
          'Ya alcanzó el limite de este producto por orden',
          'info'
        );
      }
      const productOptions = product?.options;
      for (let i = 0; i < productOptions?.length; i += 1) {
        const group = formatOptions?.filter(
          (option) => option?.groupId === productOptions[i]?._id
        );
        if (
          productOptions[i]?.type !== 'optional' &&
          productOptions[i]?.minChoices > group?.length
        ) {
          return notify(
            `Por favor seleccione al menos ${productOptions[i]?.minChoices} ${
              productOptions[i]?.minChoices === 1 ? 'opción' : 'opciones'
            } de ${productOptions[i]?.title}`,
            'info'
          );
        }
      }
      setAddingToCart(true);
      if (product?.available) {
        try {
          NProgress.start();
          const _item = {
            shopCartId: shopCart?._id,
            productId: product?._id,
            quantity: 1,
            options: formatOptions?.map((option) => ({
              title: option.title,
              value: option.value,
              price: option.price,
            })),
          };
          const { data } = await addItemToCart({
            variables: {
              data: _item,
            },
          });
          if (data?.addItemToCart) {
            setShopCart(data?.addItemToCart);
            notify('¡Producto agregado!', 'success');
            close();
            router.push('/shopping-cart');
          }
        } catch (err) {
          notify(err.message, 'info');
        } finally {
          setAddingToCart(false);
          NProgress.done();
        }
      } else {
        notify(
          'El producto no posee unidades en el inventario actualmente',
          'info'
        );
      }
    }
  };
  return (
    <Modal
      setOpen={setOpen}
      isOpen={isOpen}
      className="w-4/5 lg:w-1/2 max-h-[75vh] md:max-h-[70vh] rounded-md"
      title="Opciones y Adicionales"
    >
      <div className="flex flex-col gap-5 w-full h-full p-8 justify-center items-center">
        <OptionSelect
          options={product?.options ?? []}
          formatOptions={formatOptions}
          setFormatOptions={setFormatOptions}
        />
        {product?.active && product?.available ? (
          <button
            type="button"
            disabled={addingToCart}
            onClick={(e) => {
              e.preventDefault();
              onAddToCart(e);
            }}
            className="w-full py-2 px-8 text-sm md:text-base rounded-lg bg-primary-300 hover:bg-primary-200 text-font-white text-center font-avenir font-semibold"
          >
            Agregar
          </button>
        ) : (
          <button
            type="button"
            disabled
            className="w-full py-2 px-8 text-sm md:text-base rounded-lg bg-primary-50 text-font-white text-center font-avenir font-semibold"
          >
            No Disponible
          </button>
        )}
      </div>
    </Modal>
  );
}

export default OptionModal;
