import React from 'react';

function FestejosMarIcon({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 486.000000 466.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,466.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M1938 4272 l-217 -197 0 -1840 -1 -1840 38 -21 c20 -11 113 -64 206
-118 92 -53 171 -94 175 -91 7 8 18 740 20 1353 l1 442 283 -2 282 -3 7 -410
c4 -225 10 -629 14 -897 4 -284 10 -488 16 -488 11 0 143 74 261 147 l87 54 0
1857 0 1857 -198 198 -197 197 -280 0 -280 -1 -217 -197z m627 -210 l140 -88
6 -364 c4 -201 10 -564 13 -807 l7 -443 -285 0 -286 0 2 826 3 826 120 68 c66
37 125 68 130 69 6 0 73 -39 150 -87z"
        />
        <path
          d="M3375 3937 c-9 -437 -3 -3363 7 -3373 4 -4 82 38 173 92 l165 99 0
366 c0 201 3 448 7 548 l6 182 95 -3 95 -3 118 -170 c306 -439 375 -535 388
-535 16 0 243 121 282 150 l24 19 -39 73 c-22 40 -125 219 -229 397 l-189 325
61 54 c288 255 341 718 132 1154 -94 198 -225 371 -428 568 -213 208 -348 297
-564 374 l-97 35 -7 -352z m443 -356 c82 -71 233 -232 297 -316 58 -77 131
-225 146 -294 6 -30 9 -95 7 -146 -3 -82 -8 -101 -40 -166 -46 -93 -116 -171
-211 -237 -95 -66 -235 -134 -267 -130 l-25 3 -3 650 c-1 358 0 660 3 673 3
12 10 22 15 22 5 0 41 -27 78 -59z"
        />
        <path
          d="M1317 4163 l-167 -85 -25 -82 c-13 -44 -60 -223 -104 -396 -88 -354
-158 -608 -171 -630 -8 -12 -21 -4 -67 40 -32 31 -106 102 -165 158 -59 56
-112 102 -118 102 -30 0 -183 -288 -234 -442 -78 -234 -112 -525 -88 -763 20
-201 85 -435 176 -631 48 -105 120 -242 136 -258 17 -17 38 14 120 179 l82
164 -51 103 c-52 107 -92 230 -112 346 -8 45 -10 135 -6 277 6 199 17 285 37
285 5 0 46 -60 91 -132 134 -218 191 -300 201 -290 5 5 49 119 97 253 136 376
169 461 180 472 8 8 11 -298 11 -1049 l0 -1060 178 -105 177 -105 2 1550 c2
853 -1 1694 -5 1868 l-7 317 -168 -86z"
        />
      </g>
    </svg>
  );
}

export default FestejosMarIcon;
