/* eslint-disable react/no-danger */
/* eslint-disable @next/next/no-img-element */
import React from 'react';
import NProgress from 'nprogress';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { useShopCart } from '../../context/CartContext';
import { useNotify, useUser } from '../../hooks';
import { ADD_ITEM_TO_SHOP_CART } from '../../graphql/mutations';
import { Product, ShopCart } from '../../models';
import formatMoney from '../../lib/formatMoney';
import checkAmountInShopCart from '../../lib/checkAmountInShopCart';
import OptionModal from '../modals/OptionModal';

interface ProductCardProps {
  product: Product;
  productId: string;
}

function ProductCard({ product, productId }: ProductCardProps) {
  const router = useRouter();
  const notify = useNotify();
  const [user] = useUser();
  const [shopCart, setShopCart] = useShopCart();
  const [addingToCart, setAddingToCart] = React.useState(false);
  const [addItemToCart] = useMutation<{ addItemToCart: ShopCart }>(
    ADD_ITEM_TO_SHOP_CART
  );

  const [openModal, setOpenModal] = React.useState(false);

  const onAddToCart = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!user) {
      router.push('/login');
      notify(
        'Debe iniciar sesión para poder agregar productos al carrito',
        'info'
      );
      return;
    }
    if (shopCart) {
      if (checkAmountInShopCart(shopCart, product) === 0) {
        return notify(
          'Ya alcanzó el limite de este producto por orden',
          'info'
        );
      }
      setAddingToCart(true);
      if (product?.available) {
        if (product?.options?.length > 0) {
          setOpenModal(true);
        } else {
          try {
            NProgress.start();
            const _item = {
              shopCartId: shopCart?._id,
              productId,
              quantity: 1,
              options: [],
            };
            const { data } = await addItemToCart({
              variables: {
                data: _item,
              },
            });
            if (data?.addItemToCart) {
              setShopCart(data?.addItemToCart);
              notify('¡Producto agregado!', 'success');
              router.push('/shopping-cart');
            }
          } catch (err) {
            notify(err.msg, 'info');
          } finally {
            setAddingToCart(false);
            NProgress.done();
          }
        }
      } else {
        notify(
          'El producto no posee unidades en el inventario actualmente',
          'info'
        );
      }
    }
  };

  return (
    <div className="w-36 h-72 md:w-56 md:h-96 relative shadow-md shadow-neutral-300/50 rounded-lg cursor-pointer bg-background-100 mb-5">
      <Link href={`/product/${productId}`}>
        <a>
          <img
            src={product?.photos[0] ?? '/img/mar_logo.png'}
            alt={product?.name}
            className="w-full z-0 h-32 md:h-40 mb-1 object-center object-cover rounded-t-lg"
          />
          <div className="absolute w-full h-40 md:h-56 bottom-0 rounded-lg p-3 md:p-5 bg-background-100 shadow-sm shadow-neutral-300/50 flex flex-col justify-between">
            <p className="w-full line-clamp-2 text-left text-sm md:text-base font-semibold text-font-black font-avenir">
              {product?.name}
            </p>
            <div
              className="w-full text-left text-xs md:text-sm text-neutral-400 font-avenir line-clamp-2"
              dangerouslySetInnerHTML={{
                __html: product?.description,
              }}
            />
            <p className="w-full truncate text-left text-sm md:text-base font-semibold text-secondary-400 font-avenir">
              ${formatMoney(product?.price)}
            </p>
            {product?.active && product?.available ? (
              <button
                type="button"
                disabled={addingToCart}
                onClick={(e) => {
                  e.preventDefault();
                  onAddToCart(e);
                }}
                className="w-full h-6 md:h-9 text-sm md:text-base rounded-lg bg-primary-300 hover:bg-primary-200 text-font-white text-center font-avenir font-semibold"
              >
                Agregar
              </button>
            ) : (
              <button
                type="button"
                disabled
                className="w-full h-6 md:h-9 text-sm md:text-base rounded-lg bg-primary-50 text-font-white text-center font-avenir font-semibold"
              >
                No Disponible
              </button>
            )}
          </div>
        </a>
      </Link>
      <OptionModal
        isOpen={openModal}
        setOpen={setOpenModal}
        close={() => {
          setOpenModal(false);
        }}
        product={product}
      />
    </div>
  );
}

export default ProductCard;
