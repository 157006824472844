import React from 'react';
import {
  useClearRefinements,
  useHitsPerPage,
  UseHitsPerPageProps,
  useInstantSearch,
  useMenu,
  usePagination,
  useRefinementList,
  useSearchBox,
} from 'react-instantsearch';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TailSpin } from 'react-loader-spinner';
import { Category, PaginationInfo, Product } from '../../models';
import ProductCard from './ProductCard';

interface HomeCatalogProps {
  selectedCategory: Category;
  selectedSubCategory: string[];
  search: string;
}

function HomeCatalog({
  selectedCategory,
  selectedSubCategory,
  search,
}: HomeCatalogProps) {
  const [allHits, setAllHits] = React.useState([]);
  // algolia
  useHitsPerPage({
    items: [{ value: 20, label: '20', default: true }],
  } as UseHitsPerPageProps);
  const { results } = useInstantSearch();
  const { refine } = usePagination();

  const { query, refine: redefineText } = useSearchBox();

  const { refine: refineCategory } = useMenu({
    attribute: 'category',
  });

  const { refine: refineSubcategory } = useRefinementList({
    attribute: 'subcategory',
  });

  const { refine: clear } = useClearRefinements();

  React.useEffect(() => {
    if (results?.hits?.length > 0) {
      // Filtrar hits que ya están presentes en el estado
      const newHits = results.hits.filter(
        (hit) =>
          !allHits.some((existingHit) => existingHit.objectID === hit.objectID)
      );
      setAllHits((prevHits) => [...prevHits, ...newHits]);
    }
  }, [results.hits]);

  // filter by text
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      redefineText(search);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [search]);

  // filter by category
  React.useEffect(() => {
    if (selectedCategory) {
      // clean hits
      setAllHits([]);
      // reset pagination
      refineCategory(selectedCategory._id);
    } else if (selectedCategory === null) {
      setAllHits([]);
      clear();
    }
  }, [selectedCategory]);

  // filter by subcategory
  React.useEffect(() => {
    if (selectedSubCategory?.length > 0) {
      // clean hits
      setAllHits([]);
      clear();
      // reset pagination
      refineSubcategory(selectedSubCategory[0]);
    } else if (selectedSubCategory?.length === 0) {
      setAllHits([]);
      clear();
    }
  }, [selectedSubCategory]);

  return (
    <div className="w-full flex flex-col mt-5">
      {false ? (
        <p className="w-full flex justify-center items-center mt-5 mb-10">
          No se han encontrado resultados para su busqueda
        </p>
      ) : null}
      <div className="w-full h-auto">
        <InfiniteScroll
          dataLength={allHits?.length}
          next={() => {
            refine((results?.page ?? 0) + 1);
          }}
          hasMore={(results?.page ?? 0) + 1 < results?.nbPages}
          loader={
            <div className="mt-5 w-full flex flex-row justify-center">
              <TailSpin
                height={80}
                width={80}
                color="#353577"
                ariaLabel="loading"
              />
            </div>
          }
          endMessage={
            <h4 className="mt-5 w-full text-center text-font-black">
              Ya viste todos los productos
            </h4>
          }
          height={700}
        >
          <div
            className={`flex flex-row flex-wrap items-center gap-5 sm:gap-8 md:gap-10 lg:gap-12 py-5 ${
              selectedCategory !== null ? 'justify-start' : 'justify-center'
            }`}
          >
            {allHits.map((product) => (
              <div key={product.objectID}>
                <ProductCard product={product} productId={product?.objectID} />
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default HomeCatalog;
