/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  type ConfigureProps,
  Configure,
  InstantSearch,
} from 'react-instantsearch';
import { Hit, SearchResponse } from '@algolia/client-search';
import { useQuery } from '@apollo/client';
import { validateString } from 'avilatek-utils';
import { TailSpin } from 'react-loader-spinner';
import useAlgolia from '../hooks/useAlgolia';
import {
  FILTER_PRODUCTS_CATEGORY_SUBCATEGORY_PAGINATION,
  GET_CATEGORIES,
  GET_PRODUCTS_PAGINATION,
} from '../graphql/queries';
import { Category, PaginationInfo, Product } from '../models';
import Layout from '../components/layouts/Layout';
import Carousel from '../components/home/Carousel';
import HomeCategories from '../components/home/HomeCategories';
import HomeCatalog from '../components/home/HomeCatalog';
import SearchHeader from '../components/home/SearchHeader';
import CategoryHeader from '../components/home/CategoryHeader';

function HomePage() {
  const searchClient = useAlgolia();
  const [search, setSearch] = React.useState('');
  const [selectedCategory, setSelectedCategory] =
    React.useState<Category>(null);
  const [selectedSubCategory, setSelectedSubCategory] = React.useState([]);

  const { data: categoriesData, loading: categoriesLoading } = useQuery<{
    categories: Category[];
  }>(GET_CATEGORIES, {
    variables: {
      sort: 'NAME_ASC',
    },
    fetchPolicy: 'network-only',
  });

  const configureProps = {
    filters: 'active: true',
  } as any;

  return (
    <InstantSearch indexName="products" searchClient={searchClient}>
      <Layout search={search} setSearch={setSearch}>
        <div className="min-h-screen w-full flex flex-col">
          {search.length > 0 || selectedCategory ? null : <Carousel />}
          {categoriesLoading ? (
            <div className="mt-5 w-full flex flex-row justify-center">
              <TailSpin
                height={80}
                width={80}
                color="#353577"
                ariaLabel="loading"
              />
            </div>
          ) : (
            <div>
              {search.length === 0 && !selectedCategory ? (
                <HomeCategories
                  categories={categoriesData?.categories ?? []}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                />
              ) : search.length === 0 && selectedCategory ? (
                <CategoryHeader
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  selectedSubCategory={selectedSubCategory}
                  setSelectedSubCategory={setSelectedSubCategory}
                />
              ) : null}

              {search.length > 0 ? <SearchHeader search={search} /> : null}
              <HomeCatalog
                selectedCategory={selectedCategory}
                selectedSubCategory={selectedSubCategory}
                search={search}
              />
            </div>
          )}
        </div>
      </Layout>
      <Configure {...(configureProps as ConfigureProps)} />
    </InstantSearch>
  );
}

export default HomePage;
