import React from 'react';

interface SearchHeaderProps {
  search: string;
}

function SearchHeader({ search }: SearchHeaderProps) {
  return (
    <div className="w-full flex flex-row text-base md:text-xl font-semibold font-century">
      <p className="text-neutral-500 mr-3">Resultados encontrados para</p>
      <p className="text-primary-300">{search}</p>
    </div>
  );
}

export default SearchHeader;
