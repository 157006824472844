import React from 'react';
import { Category } from '../../models';
import CloseIcon from '../icons/CloseIcon';
import SubCategoryItem from './SubCategoryItem';

interface CategoryHeaderProps {
  selectedCategory: Category;
  setSelectedCategory: React.Dispatch<React.SetStateAction<Category>>;
  selectedSubCategory: string[];
  setSelectedSubCategory: React.Dispatch<React.SetStateAction<string[]>>;
}

function CategoryHeader({
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
}: CategoryHeaderProps) {
  return (
    <div className="w-full flex flex-col font-semibold font-century">
      <div className="flex flex-row items-center mb-2 ">
        <button
          type="button"
          className="text-neutral-300 hover:text-neutral-200"
          onClick={(e) => {
            e.preventDefault();
            setSelectedCategory(null);
            setSelectedSubCategory(null);
          }}
        >
          <CloseIcon className="w-4 h-4 mr-3" />
        </button>
        <p className="text-neutral-500 text-lg">{selectedCategory?.name}</p>
      </div>
      <div className="w-full flex flex-row gap-5 flex-wrap">
        {selectedCategory?.subcategories.map((subcategory) => (
          <div key={subcategory?._id}>
            <SubCategoryItem
              subcategory={subcategory}
              selectedSubCategory={selectedSubCategory}
              setSelectedSubCategory={setSelectedSubCategory}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CategoryHeader;
