/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { Category } from '../../models';
import FestejosMarIcon from '../icons/FestejosMarIcon';
import MarExpressIcon from '../icons/MarExpressIcon';
import OtherIcon from '../icons/OtherIcon';

interface HomeCategoryProps {
  category?: Category;
  other?: boolean;
  setSelectedCategory: React.Dispatch<React.SetStateAction<Category>>;
  setOpdenAllCategoriesModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

function HomeCategoryItem({
  category,
  other = false,
  setSelectedCategory,
  setOpdenAllCategoriesModal,
}: HomeCategoryProps) {
  return (
    <div className="w-20 h-20 lg:w-24 lg:h-24 xl:w-28 xl:h-28 shadow-md hover:shadow-lg shadow-neutral-300/50 rounded-lg font-avenir bg-background-100 text-primary-300 flex flex-col justify-center items-center">
      {other ? (
        <button
          type="button"
          className="w-full h-full flex flex-col justify-between py-2 items-center"
          onClick={(e) => {
            e.preventDefault();
            setOpdenAllCategoriesModal(true);
          }}
        >
          <OtherIcon className="w-8 lg:w-10 xl:w-12 pt-2" />
          <p className="w-full text-center text-sm lg:text-base">Ver todo</p>
        </button>
      ) : (
        <button
          type="button"
          className="w-full h-full flex flex-col justify-center items-center"
          onClick={(e) => {
            e.preventDefault();
            setSelectedCategory(category);
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }}
        >
          {category?.icon ? (
            <div className="w-full h-2/3 flex justify-center items-center">
              <img
                className="w-8 lg:w-10 xl:w-12 object-center object-cover selected-category"
                src={category?.icon}
                alt={category?.name}
              />
            </div>
          ) : (
            <div className="w-full h-2/3 flex justify-center items-center">
              <FestejosMarIcon className="w-8 lg:w-10 xl:w-12" />
            </div>
          )}

          <p className="w-full text-center text-sm lg:text-base line-clamp-2 px-1">
            {category?.name}
          </p>
        </button>
      )}
    </div>
  );
}

export default HomeCategoryItem;
