import React from 'react';
import { Category } from '../../models';
import Modal from '../common/Modal';
import HomeCategoryItem from '../home/HomeCategoryItem';
import CloseIcon from '../icons/CloseIcon';

interface AllCategoriesModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  close: () => void;
  categories: Category[];
  setSelectedCategory: React.Dispatch<React.SetStateAction<Category>>;
}

function AllCategoriesModal({
  categories,
  setSelectedCategory,
  isOpen,
  setOpen,
  close,
}: AllCategoriesModalProps) {
  return (
    <Modal
      setOpen={setOpen}
      isOpen={isOpen}
      className="w-4/5 lg:w-1/2 h-1/2 md:h-auto rounded-md"
      title="Nuestras Categorías"
    >
      <div className="flex flex-row flex-wrap gap-x-1 gap-y-5 lg:gap-5 w-full p-8 justify-center items-center">
        {categories.map((category) => (
          <div key={category._id}>
            <HomeCategoryItem
              category={category}
              setSelectedCategory={setSelectedCategory}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
}

export default AllCategoriesModal;
