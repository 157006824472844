import React from 'react';

function OtherIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 322.000000 282.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,282.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M576 2615 c-85 -35 -139 -86 -186 -179 -27 -52 -30 -69 -30 -146 0
-77 4 -94 30 -147 35 -71 111 -145 178 -176 38 -18 66 -22 142 -22 84 0 102 3
151 28 67 32 142 109 172 175 18 38 22 65 22 142 -1 83 -4 102 -28 152 -36 73
-119 150 -192 177 -79 30 -182 28 -259 -4z"
        />
        <path
          d="M1483 2619 c-67 -26 -150 -101 -188 -171 -28 -49 -30 -61 -30 -153 0
-82 4 -109 22 -147 30 -66 105 -143 172 -175 49 -25 68 -28 151 -28 84 0 102
3 152 28 121 59 189 164 196 301 3 67 0 92 -19 141 -31 82 -121 173 -202 203
-76 29 -181 29 -254 1z"
        />
        <path
          d="M2399 2627 c-88 -33 -174 -112 -212 -195 -18 -38 -22 -66 -22 -142 0
-84 3 -102 28 -152 64 -130 201 -207 348 -195 38 3 89 15 113 26 65 28 141
103 176 174 26 53 30 71 30 146 0 74 -4 94 -29 146 -33 71 -110 147 -179 178
-59 26 -199 34 -253 14z"
        />
        <path
          d="M1543 1734 c-170 -34 -272 -154 -281 -329 -3 -65 0 -91 18 -137 27
-72 99 -153 172 -193 49 -28 60 -30 158 -30 97 0 109 2 158 29 61 34 130 105
164 167 20 38 23 58 23 144 0 87 -4 107 -27 156 -67 144 -228 225 -385 193z"
        />
        <path
          d="M2441 1734 c-112 -23 -202 -93 -250 -194 -22 -47 -26 -68 -26 -150 0
-76 5 -105 22 -142 31 -67 105 -143 176 -178 53 -26 70 -30 146 -30 104 0 164
24 242 97 76 71 102 128 107 240 4 82 2 97 -21 147 -72 156 -234 243 -396 210z"
        />
        <path
          d="M593 1722 c-96 -34 -154 -86 -203 -182 -18 -35 -25 -66 -28 -137 -4
-86 -2 -96 25 -155 61 -129 167 -200 308 -206 64 -3 92 1 137 18 76 28 159
104 195 178 24 50 27 69 28 152 0 77 -4 104 -22 142 -30 65 -105 143 -169 174
-73 36 -196 43 -271 16z"
        />
        <path
          d="M593 821 c-64 -24 -98 -47 -147 -102 -63 -69 -86 -130 -86 -229 0
-73 4 -93 29 -147 36 -75 98 -140 166 -172 74 -36 200 -41 277 -11 76 28 159
104 195 178 25 50 28 68 28 152 0 113 -17 157 -89 237 -67 74 -137 106 -240
110 -62 3 -93 -1 -133 -16z"
        />
        <path
          d="M1510 826 c-61 -16 -109 -47 -160 -102 -61 -66 -83 -123 -88 -226 -4
-76 -2 -92 21 -142 35 -77 109 -153 182 -187 52 -25 72 -29 146 -29 75 0 93 4
146 30 71 35 145 111 176 178 31 67 31 217 0 284 -25 54 -90 128 -139 159 -74
45 -193 60 -284 35z"
        />
        <path
          d="M2408 827 c-100 -38 -182 -111 -221 -196 -17 -36 -22 -66 -22 -141 0
-114 19 -163 92 -241 71 -76 128 -102 240 -107 86 -4 96 -2 155 25 88 41 131
83 173 165 32 65 35 78 35 158 0 77 -4 94 -30 147 -36 71 -111 146 -177 175
-59 26 -193 34 -245 15z"
        />
      </g>
    </svg>
  );
}

export default OtherIcon;
